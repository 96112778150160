

































































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from "../../mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    CenteredColumnLayout,
    PageTitle
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class ChatRules extends Mixins(UseFields, InputSetups, TariffsTagsHelper) {
  pageHelpMessageIsOpen = false
}
