var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('top_message_config_page_description'))}}),_c('span',{staticClass:"underline hover:cursor-pointer",on:{"click":function($event){_vm.pageHelpMessageIsOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('more'))+" ")])]),_c('a-modal',{staticClass:"top-message-config-modal",attrs:{"title":_vm.$t('hint'),"centered":"","footer":null},model:{value:(_vm.pageHelpMessageIsOpen),callback:function ($$v) {_vm.pageHelpMessageIsOpen=$$v},expression:"pageHelpMessageIsOpen"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('top_message_config_page_help_message'))}})])]},proxy:true},{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'toprepmessage',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'bottomrepmessage',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('a-divider'),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'topapmessage',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'bottomapmessage',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('a-divider'),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'topmsg',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'bottommsg',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('a-divider'),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'topxp',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'bottomxp',
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'tariffTags': _vm.getTagsByFieldKey('custom_tops'),
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('a-divider'),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'allow_send_pm_from_external_sources',
          'tariffTags': _vm.getTagsByFieldKey('allow_send_pm_from_external_sources'),
          'hasAnchor': true,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }